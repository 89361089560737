<template>
  <div
    :style="fadeStyles"
    class="LinkButtonListingBlock layout-container mb-48 sm:mb-64 sm:min-h-[150px]"
    :class="{
      'px-0 md:px-56': model.useSlider,
      'sm:px-24': model.backgroundColor,
    }"
  >
    <div
      class="rounded-md"
      :class="{
        'px-0 sm:py-24 md:py-32 md:px-24': model.backgroundColor,
        'py-24 sm:py-0': model.backgroundColor && !model.useSlider,
      }"
      :style="{
        background: backgroundColor,
      }"
    >
      <h2
        class="text-lg md:text-2xl text-center mb-16 md:mb-24"
        :class="{
          'text-white': !isLightBackground
        }"
      >
        {{ model.title }}
      </h2>
      <client-only>
        <component
          :is="model.useSlider ? Swiper:'div'"
          :modules="model.useSlider ? modules : null"
          :navigation="true"
          :slides-per-view="'auto'"
          :space-between="8"
          :center-insufficient-slides="true"
          :class="{
            'link-button-listing-swiper !px-24 ': model.useSlider,
            'md:!px-0': model.useSlider && backgroundColor === '',
            'flex justify-center items-start flex-wrap': !model.useSlider,
            isLightBackground,
            '!px-24 md:!px-0': model.useSlider
          }"
        >
          <template v-if="model.useSlider">
            <swiper-slide
              v-for="(link, index) in model.links"
              :key="index"
              class="!w-auto"
            >
              <nuxt-link
                :to="link.linkUrl"
                class="btn btn--secondary btn--sm block"
              >
                {{ link.text }}
              </nuxt-link>
            </swiper-slide>
          </template>
          <template v-else>
            <nuxt-link
              v-for="(link, index) in model.links"
              :key="index"
              :to="link.linkUrl"
              class="btn btn--secondary btn--sm mx-4 mb-8 block"
            >
              {{ link.text }}
            </nuxt-link>
          </template>
        </component>
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { type LinkButtonListingBlock } from '~/content-types';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';

const modules = [ SwiperNavigation ];
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const props = defineProps<{
  model: LinkButtonListingBlock;
}>();

function isLight(color: string) {
  if (isMobile.value && props.model.useSlider) {
    return true;
  }
  if (color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
  }
  return true;
}

const backgroundColor = computed(()=> {
  return (isMobile.value && props.model.useSlider) ? '' : props.model.backgroundColor;
});

const isLightBackground = computed(()=> {
  return backgroundColor.value ? isLight(backgroundColor.value) : true;
});

const fadeStyles = computed(()=> {
  if (backgroundColor.value && backgroundColor.value !== '') {
    return `--fstart: ${backgroundColor.value};` +
      `--fmiddle: ${backgroundColor.value}cc;` +
      `--fend: ${backgroundColor.value}00`;
  }
  return '';
});
</script>

<style scoped lang="postcss">
</style>

<style lang="postcss">
/* this needs to be global */
/*
.blockAreaBottom {
  .LinkButtonListingBlock {
    @apply mx-24;
  }
}
*/

.link-button-listing-swiper .swiper-wrapper {
  @apply py-4 h-[50px];
}

.link-button-listing-swiper .swiper-button-next,
.link-button-listing-swiper .swiper-button-prev {
  @apply -mt-6;
  height: 38px;
  width: 38px;
}

.link-button-listing-swiper .swiper-button-next:after,
.link-button-listing-swiper .swiper-button-prev:after {
  height: 38px;
  width: 38px;
  background-size: 50%;
  @apply border border-grey400 bg-center bg-white rounded-full z-10;
}

.link-button-listing-swiper .swiper-button-next {
  @apply right-0;
}

.link-button-listing-swiper .swiper-button-next:before,
.link-button-listing-swiper .swiper-button-prev:before {
  content: '';
  height: calc(100% + 10px);
  margin-top: -5px;
  @apply absolute w-144 right-0 top-0;
}

.link-button-listing-swiper .swiper-button-next:before {
  background-image: linear-gradient(
    270deg,
    var(--fstart, #ffffff) 0%,
    var(--fstart, #ffffff) 40%,
    var(--fmiddle, #ffffffcc) 70%,
    var(--fend, #ffffff00) 100%
  );
  background-color: rgba(255, 255, 255, 0);
}

.link-button-listing-swiper .swiper-button-prev:before {
  background-image: linear-gradient(
    90deg,
    var(--fstart, #ffffff) 0%,
    var(--fstart, #ffffff) 40%,
    var(--fmiddle, #ffffffcc) 70%,
    var(--fend, #ffffff00) 100%
  );
  @apply left-0;
}

.link-button-listing-swiper .swiper-button-prev {
  @apply left-0;
}
</style>
